import React from 'react'
import { Link } from 'gatsby';

import Layout from '../../components/layout'
import SEO from '../../components/SEO/seo'

import './404.scss';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="404" />
    <div className="error-404">
      <h1>
        <div className="logo" />
        Page not found
      </h1>
      <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable</p>
      <Link to="/">
        <button className="btn-secondary">Homepage</button>
      </Link>
      <Link to="/products/stratodem-research-suite">
        <button className="btn-primary">StratoDem Research Suite</button>
      </Link>
    </div>
  </Layout>);

export default NotFoundPage
